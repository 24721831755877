import {
  email,
  alpha,
  minLength,
  helpers,
  url,
  required
} from 'vuelidate/lib/validators'
const regexAlpha = helpers.regex('alpha', /linkedin.com/)

export const vacanciesForm = {
  name: {
    required,
    alpha,
    minLength: minLength(3)
  },
  linkedin: {
    required,
    url,
    regexAlpha
  },
  email: {
    required,
    email
  }
}
export const feedbackForm = {
  full_name: {
    required,
    alpha,
    minLength: minLength(3)
  },
  email: {
    required,
    email
  },
  additional_info: {
    required,
    minLength: minLength(3)
  }
}
