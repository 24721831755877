<template lang="pug">
  div.feedback__section
    div.dialog__window.feedback
      div.dialog__window-top
        span
      div.dialog__window-content
        div.feedback__body
          h2.feedback__title.h2.fw-700 Get in touch
          form(
            method="POST"
          ).form
            label.form__label(
              for="full_name"
              :class="$v.form.full_name.$error && 'invalid'"
            )
              span Name
              input.input(
                type="text"
                name="name"
                v-model="form.full_name"
              )
            label.form__label(
              for="email"
              :class="$v.form.email.$error && 'invalid'"
            )
              span Email
              input.input(
                type="email"
                name="email"
                v-model="form.email"
              )
            label.form__label(for="phone")
              span Phone
              input.input(
                type="tel"
                name="phone"
                v-model="form.phone_number"
              )
            label.form__label(
              for="additional_info"
              :class="$v.form.additional_info.$error && 'invalid'"
            )
              span How can we help you?
              textarea.input(
                name="additional_info"
                v-model="form.additional_info"
              )
            button(
              type="submit"
              @click.prevent="submit"
            ).btn.btn__send
              span Send

    ThanksPopup(ref="thanksPopup")
</template>

<script>
import { feedbackForm } from '@/configs/validationRules'
export default {
  name: 'FeedbackForm',
  components: {
    ThanksPopup: () => import('@/components/ThanksPopup')
  },
  data () {
    return {
      form: {
        email: '',
        full_name: '',
        phone_number: '',
        additional_info: ''
      }
    }
  },
  validations () {
    return { form: feedbackForm }
  },
  methods: {
    validation () {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    submit () {
      this.validation()
      if (this.validation()) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            full_name: this.form.full_name,
            email: this.form.email,
            phone_number: this.form.phone_number,
            additional_info: this.form.additional_info
          })
        }
        fetch(`${ process.env.VUE_APP_MAIL }/api/v1/contact_me_form/`, requestOptions)
          .then(response => {
            if (response?.status === 200) {
              if (!this.$v.$invalid) {
                for (let key in this.form) {
                  this.form[key] = ''
                }
              }
              this.$v.form.$reset()
              this.$refs.thanksPopup.waitPopup()
            }
          })
      }
    }
  }
}
</script>
